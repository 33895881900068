import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import imgDeco from "../images/deco.png";

const SectionTitle = styled.div`
  grid-column: 3 / span 8;
  grid-row: 1;
  max-width: 100%;
  text-align: center;
  margin-top: 72px;

  .imgDeco {
    width: 374px;
    height: 8px;
    margin: 8px 0px 8px 0px;
    background: center / contain no-repeat;
    display: inline-block;
  }
  .text-header {
    color: ${colors.teal};
  }
  .text-miniheader {
    margin-bottom: 32px;
    text-align: center;
    color: white;
  }

  &.theme-dark {
    .text-header {
      color: ${colors.teal};
    }
    .text-miniheader {
      color: ${colors.teal};
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-column: 2 / span 6;
    margin-top: 36px;
    svg,
    .text-header,
    .text-miniheader {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    grid-column: 1 / span 2;
    margin-bottom: 16px;
    margin-top: 24px;

    .imgDeco {
      width: 200px;
      height: 8px;
      margin: 8px 0px 8px 0px;
      background: center / contain no-repeat;
      display: inline-block;
    }
    .imgBomb {
      width: 75px;
      height: 75px;
    }

    svg {
      width: 50px;
      height: 50px;
      margin-bottom: 8px;
    }
    .text-header {
      margin: 8px;
    }
    .text-miniheader {
      margin-bottom: 8px;
    }
  }
`;

interface Props {
  colorTheme: "light" | "dark";
  title: string;
}

export default class ButtonText extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const colorClass = `theme-${this.props.colorTheme}`;

    return (
      <SectionTitle className={colorClass}>
        <div className="text-header">{this.props.title}</div>
        <div className="imgDeco" style={{ backgroundImage: `url("${imgDeco}")` }}></div>
        {this.props.children && <div className="text-miniheader">{this.props.children}</div>}
      </SectionTitle>
    );
  }
}
